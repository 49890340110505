import { Tab } from './state';
import _ from 'lodash';
import { tabMapping } from './actions';
import { StockRequestGroup } from 'models/StockRequestGroup';
import {
  countPurchaseQty,
  countTransferQty,
  restockForSupplySite,
} from 'helpers/stockRequest';
import { StockRequest } from 'models/StockRequest';

function SAReducer(state: any, action: any) {
  switch (action.type) {
    case 'START_FETCHING':
      return {
        ...state,
        fetching: [...state.fetching, action.payload],
      };
    case 'STOP_FETCHING':
      return {
        ...state,
        fetching: [...state.fetching.filter((x: Tab) => x !== action.payload)],
      };
    case 'SET_STOCK_ALERTS':
      return {
        ...state,
        stockAlerts: [...action.payload],
      };
    case 'SET_SINGLE_STOCK_REQUEST_GROUP': {
      const { tab, data } = action.payload;
      let stockRequestGroup = _.cloneDeep(state[tabMapping[tab as Tab]]);

      const foundIdx = stockRequestGroup.findIndex(
        (group: StockRequestGroup) =>
          group.stock.sku === data.stock.sku &&
          group.fromInventory.siteId === data.fromInventory.siteId &&
          group.state === data.state
      );

      stockRequestGroup[foundIdx] = data;

      if (stockRequestGroup[foundIdx].children.length > 0) {
        stockRequestGroup[foundIdx].stQuantity = countTransferQty(
          stockRequestGroup[foundIdx]
        );
        if (tab === '1') restockForSupplySite(stockRequestGroup[foundIdx]);
        stockRequestGroup[foundIdx].poQuantity = countPurchaseQty(
          stockRequestGroup[foundIdx]
        );
      }

      //remove empty StockRequestGroup
      stockRequestGroup = stockRequestGroup.filter(
        (group: StockRequestGroup) => group.children.length > 0
      );
      // if tab === 2, available stock === 0, remove!
      if (tab === '2')
        stockRequestGroup = stockRequestGroup.filter(
          (group: StockRequestGroup) => group.fromInventory.quantity > 0
        );
      //recalculate index
      stockRequestGroup.forEach((item: StockRequestGroup, index: number) => {
        item.index = index;
      });
      return {
        ...state,
        [tabMapping[tab as Tab]]: stockRequestGroup,
      };
    }
    case 'SET_SINGLE_PURCHASE_ALERT': {
      let requests = _.cloneDeep(state.purchaseAlerts);
      const foundIdx = requests.findIndex(
        (sr: StockRequest) => sr.id === action.payload.id
      );
      requests[foundIdx] = action.payload;
      return {
        ...state,
        purchaseAlerts: requests,
      };
    }
    case 'SET_TRANSFER_ALERTS':
      return {
        ...state,
        transferAlerts: [...action.payload],
      };
    case 'SET_PURCHASE_ALERTS':
      return {
        ...state,
        purchaseAlerts: [...action.payload],
      };
    case 'SET_PROCESSING_DATA':
      return {
        ...state,
        processing: [...action.payload],
      };
    case 'UPDATE_FITER':
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    case 'UPDATE_SEARCH':
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        },
      };
    case 'SET_ACTIVE':
      return {
        ...state,
        active: action.payload,
      };
    default:
      return state;
  }
}

export default SAReducer;
