import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { listSupplySites } from 'services/apis/common';
import { listCategories } from 'services/apis/categories';

//contexts
const AppStateContext = createContext<any | undefined>(undefined);
const AppDispatchContext = createContext<any | undefined>(undefined);

//state

const initialState = {
  categories: [],
  supplySites: [],
  fetching: false,
};

//reducer
function AppReducer(state: any, action: any) {
  switch (action.type) {
    case 'START_FETCHING':
      return {
        ...state,
        fetching: true,
      };
    case 'STOP_FETCHING':
      return {
        ...state,
        fetching: false,
      };
    case 'SET_CATEGORIES':
      return {
        ...state,
        categories: action.payload,
      };
    case 'SET_SUPPLY_SITES':
      return {
        ...state,
        supplySites: action.payload,
      };
    default:
      return state;
  }
}

//context provider
const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  useEffect(() => {
    getSupplySites(dispatch);
  }, []);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

function useAppState() {
  const context = useContext(AppStateContext);
  return context;
}

function useAppDispatch() {
  const dispatch = useContext(AppDispatchContext);
  return dispatch;
}

function useApp() {
  return [useAppState(), useAppDispatch()];
}
export { AppProvider, useAppState, useAppDispatch, useApp };

//actions
export function startFetching(dispatch: any) {
  dispatch({ type: 'START_FETCHING' });
}

export function stopFetching(dispatch: any) {
  dispatch({ type: 'STOP_FETCHING' });
}

export function setCategories(dispatch: any, payload: any) {
  dispatch({ type: 'SET_CATEGORIES', payload });
}

export function setSupplySites(dispatch: any, payload: any) {
  const supplySites = payload.map((x: any) => ({
    ...x,
    displayName: `[${x.id}] ${x.name}`,
  }));
  dispatch({ type: 'SET_SUPPLY_SITES', payload: supplySites });
}

export async function getCategories(dispatch: any) {
  const response = await listCategories();
  if (response && response.ok) {
    setCategories(dispatch, response.categories);
  } else {
    setCategories(dispatch, []);
  }
}

export async function getSupplySites(dispatch: any) {
  const response = await listSupplySites();
  if (response?.ok) {
    setSupplySites(dispatch, response.supplySites);
  }
}
