import apiService from './base';

export const listCategories: any = () => {
  return apiService({
    url: '/api/v1/categories',
  });
};

export const postSelectCategories: any = (ids: string[]) => {
  return apiService({
    url: '/api/v1/select-categories',
    method: 'POST',
    data: { ids },
  });
};

export const listSelectedCategories: any = () => {
  return apiService({
    url: '/api/v1/select-categories',
  });
};
