import {
  PurchaseCommand,
  PurchaseCommandStatus,
  PurchaseOrder,
} from 'models/PurchaseCommand';
import { StockRequest } from 'models/StockRequest';
import moment from 'moment';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { listPurchaseCommands } from 'services/apis/purchase';
import slugify from 'slugify';

//contexts
const POStateContext = createContext<any | undefined>(undefined);
const PODispatchContext = createContext<any | undefined>(undefined);

//state
//get last search from local storage or set default

const initialState = {
  filter: {
    searchKey: '',
    searchBy: 'sku',
  },
  search: {
    onlyMe: false,
  },
  fetching: false,
  data: [],
};

//reducer
function POReducer(state: any, action: any) {
  switch (action.type) {
    case 'START_FETCHING':
      return {
        ...state,
        fetching: true,
      };
    case 'STOP_FETCHING':
      return {
        ...state,
        fetching: false,
      };
    case 'SET_PO_DRAFT':
      return {
        ...state,
        data: action.payload,
      };
    case 'UPDATE_FITER':
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    case 'UPDATE_SEARCH':
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        },
      };
    case 'SET_SINGLE_PURCHASE_COMMAND':
      let updatedData = [...state.data];
      updatedData[action.payload.index] = action.payload;
      return {
        ...state,
        data: updatedData,
      };
    default:
      return state;
  }
}

//context provider
const PoProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(POReducer, initialState);

  useEffect(() => {
    getPODrafts(dispatch, state);
    //eslint-disable-next-line
  }, []);

  return (
    <POStateContext.Provider value={state}>
      <PODispatchContext.Provider value={dispatch}>
        {children}
      </PODispatchContext.Provider>
    </POStateContext.Provider>
  );
};

function usePoState() {
  const context = useContext(POStateContext);
  return context;
}

function usePoDispatch() {
  const dispatch = useContext(PODispatchContext);
  return dispatch;
}

function usePendingOrder() {
  return [usePoState(), usePoDispatch()];
}
export { PoProvider, usePoState, usePoDispatch, usePendingOrder };

//actions
export function startFetching(dispatch: any) {
  dispatch({ type: 'START_FETCHING' });
}

export function stopFetching(dispatch: any) {
  dispatch({ type: 'STOP_FETCHING' });
}

export function setPODrafts(dispatch: any, payload: PurchaseCommand[]) {
  const pcs = payload.filter(
    (x: PurchaseCommand) => x.purchaseOrders.length > 0
  );
  pcs.forEach((x: PurchaseCommand, index: number) => (x.index = index));
  dispatch({ type: 'SET_PO_DRAFT', payload: pcs });
}

export const updateFilter = async (dispatch: any, payload: any) => {
  dispatch({ type: 'UPDATE_FITER', payload });
};

export const getPODrafts = async (dispatch: any, state: any) => {
  startFetching(dispatch);
  const params = {
    states: [PurchaseCommandStatus.open],
    onlyMe: state.search.onlyMe,
  };
  const response = await listPurchaseCommands(params);

  if (response && response.ok) {
    const _enrichedData: PurchaseCommand[] = response.purchaseCommands.map(
      (x: any, index: number) => ({
        index: index,
        ...x,
        mov: x.mov,
        purchaseOrders: x.purchaseOrders.map((po: any) => ({
          ...po,
          requestedQuantity: po.quantity,
          selectedPrice: po.price[0] || 0,
          selectedVat: (po.vat[0] || {}).amount,
          selectedUom: (po.uom[0] || {}).code,
          stockRequests: null,
          displayName: slugify(`${po.sku} ${po.name}`, {
            locale: 'vi',
            lower: true,
          }),
          selected: true,
        })),
      })
    );
    setPODrafts(dispatch, _enrichedData);
  } else {
    setPODrafts(dispatch, []);
  }
  stopFetching(dispatch);
};

export function updateSearch(dispatch: any, item: any, state: any) {
  dispatch({ type: 'UPDATE_SEARCH', payload: item });
}

export function setSinglePurchaseCommands(
  dispatch: any,
  purchaseCommand: PurchaseCommand
) {
  //calculate late request
  const allStockRequests: StockRequest[] = purchaseCommand.purchaseOrders
    .map((x: PurchaseOrder) => x.stockRequests || [])
    .flat();
  purchaseCommand.lateStockRequests = allStockRequests.filter(
    (x: StockRequest) => {
      const edd = moment.unix(parseInt(x.expectedReceivedAt || ''));
      const oos = moment.unix(parseInt(x.inventory.forecastOos));
      return edd.isAfter(oos);
    }
  ).length;
  dispatch({ type: 'SET_SINGLE_PURCHASE_COMMAND', payload: purchaseCommand });
}

export function removePurchaseCommand(dispatch: any, pcId: number, state: any) {
  let list = state.data.filter((x: PurchaseCommand) => x.id !== pcId);
  list.forEach((element: PurchaseCommand, index: number) => {
    element.index = index;
  });
  setPODrafts(dispatch, list);
}
