const IAM_SCOPES = [
  'openid',
  'profile',
  // You can add more scopes (granted services) here
  'catalog',
  'read:permissions',
];

export default {
  IAM_SCOPES,
  ALL: 'all',
  TABLET_WIDTH: 768,
};

export type AlignType = 'left' | 'center' | 'right';
