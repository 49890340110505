import { PurchaseCommandStatus } from 'models/PurchaseCommand';
import apiService from './base';

interface ListPurchaseCommandsParams {
  sku?: string;
  siteId?: string;
  supplierId: string;
  states?: PurchaseCommandStatus[];
}

export const listPurchaseCommands: any = (
  params: ListPurchaseCommandsParams
) => {
  return apiService({
    url: '/api/v1/purchase-commands',
    params,
  });
};

export const getPurchaseCommand: any = (id: number) => {
  return apiService({
    url: `/api/v1/purchase-command/${id}`,
  });
};

export type ConfirmPurchaseCommandParams = {
  id: number;
  purchaseOrders: any[];
  fromSiteId: string;
  supplierId: string;
  expectedReceivedAt: string; //timestamp
};

export const postConfirmPurchaseCommands: any = (
  data: ConfirmPurchaseCommandParams[]
) => {
  return apiService({
    url: '/api/v1/confirm-purchase-command',
    method: 'post',
    data: {
      purchaseCommands: data,
    },
  });
};

export type UpdatePurchaseCommandParams = {
  id: number;
};

export const postCancelPurchaseCommand: any = (id: number, reason: string) => {
  return apiService({
    url: `/api/v1/cancel-purchase-command/${id}`,
    method: 'post',
    data: {
      reason: reason,
    },
  });
};

export const postUpdatePurchaseCommand: any = (
  data: ConfirmPurchaseCommandParams
) => {
  return apiService({
    url: `/api/v1/purchase-command`,
    method: 'post',
    data: {
      purchaseCommand: data,
    },
  });
};
