import { lazy } from 'react';
import { HomeOutlined, AppstoreOutlined } from '@ant-design/icons';
// import {
//   appConstants,
//   resourceConstants,
//   actionConstants,
// } from 'constants/index';
import { t } from 'helpers/i18n';

// const { CATALOG } = appConstants;
// const { PRODUCT } = resourceConstants;
// const { READ, CREATE, UPDATE } = actionConstants;

// const permission = (app: string, resource: string, action?: string) => ({
//   app,
//   resource,
//   action,
// });

// App pages
const StockAlertComponent = lazy(() => import('pages/StockAlert'));
const PendingOrdersComponent = lazy(() => import('pages/PendingOrders'));

/*
 * If route has children, it's a parent menu (not link to any pages)
 * You can change permissions to your IAM's permissions for testing
 */
const routes = [
  // This is a menu/route which has no children (sub-menu)
  {
    exact: true,
    path: '/stock-requests',
    name: t('StockAlert'),
    component: StockAlertComponent,
    icon: HomeOutlined,
  },
  // This is a parent menu which has children (sub-menu) and requires catalog:product:X permission to display
  // X maybe read/create/update/delete...
  {
    path: '/stock-requests/pending-orders',
    name: t('PendingOrder'),
    component: PendingOrdersComponent,
    // permissions: [permission(CATALOG, PRODUCT)],
    icon: AppstoreOutlined,
    hidden: true,
  },
];

export default routes;
