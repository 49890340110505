import { Tab } from 'contexts/SAContext/state';
import { LeadTime } from 'models/LeadTime';
import { StockRequest, StockRequestType } from 'models/StockRequest';
import { StockRequestGroup } from 'models/StockRequestGroup';
import { StockRequestStatus } from 'models/StockRequestStatus';
import { SuggestedCommand } from 'models/SuggestedCommand';
import { Supplier } from 'models/Supplier';
import moment from 'moment';
import slugify from 'slugify';

export function sortByState(stockAlerts: StockRequestGroup[]) {
  //sort by state
  const state = {
    [StockRequestStatus.open]: 0,
    [StockRequestStatus.requested]: 1,
    [StockRequestStatus.unspecified]: 2,
    [StockRequestStatus.rejected]: 2,
    [StockRequestStatus.expired]: 2,
    [StockRequestStatus.completed]: 2,
    [StockRequestStatus.pending]: 2,
  };
  stockAlerts.sort(
    (a: StockRequestGroup, b: StockRequestGroup) =>
      state[a.state] - state[b.state]
  );
}

export function validateStockAlert(alertGroup: StockRequestGroup) {
  const stockAlerts = alertGroup.children;
  const reducer = (
    prevValue: number,
    element: StockRequest,
    currentIndex: number
  ) => {
    const currentStQuantity = [
      SuggestedCommand.st,
      SuggestedCommand.stOnly,
      SuggestedCommand.stOos,
    ].includes(element.command)
      ? element.quantity
      : 0;

    const availableStock =
      alertGroup.fromInventory.quantity +
      alertGroup.fromInventory.inComingQuantity;

    const ableToStockTransfer =
      availableStock - (prevValue + currentStQuantity);

    if (ableToStockTransfer < 0) {
      if (element.command === SuggestedCommand.stOos) {
        element.quantity = 0;
      } else {
        element.command = SuggestedCommand.po;
      }
      element.ableToStockTransfer =
        alertGroup.fromInventory.quantity - prevValue;
      return prevValue;
    } else {
      element.ableToStockTransfer = ableToStockTransfer;
      return prevValue + currentStQuantity;
    }
  };
  stockAlerts.reduce(reducer, 0);
}

export async function restockForSupplySite(alertGroup: StockRequestGroup) {
  //TODO: bổ sung thêm ĐK nếu sku status = hết hàng/ tạm hết thì ko restock
  if (alertGroup.state !== StockRequestStatus.open) {
    return;
  }
  // found exists stock request restock for supply site
  const alertForDCs: StockRequest[] = alertGroup.children.filter(
    (x: StockRequest) => x.inventory.siteId === alertGroup.fromInventory.siteId
  );

  /* 
    Tính lại thông số:
    - Tồn khả dụng = Tồn real time - total transfer qty
    - Suggested qty = Roundup(backorder + min stock - (tồn real time - total transfer qty + incoming))
  */
  const quantity =
    alertGroup.fromInventory.quantity - (alertGroup.stQuantity || 0);

  if (alertForDCs.length === 0) {
    const suggestedQuantity = Math.ceil(
      alertGroup.fromInventory.minQuantity -
        quantity -
        alertGroup.fromInventory.inComingQuantity
    );
    if (suggestedQuantity > 0) {
      alertGroup.children = [
        ...alertGroup.children,
        createStockRequestForDC(alertGroup, quantity, suggestedQuantity),
      ];
    }
  } else {
    alertForDCs.forEach((alert: StockRequest) => {
      const backorderQty = alert.backorderQuantity || 0;
      const suggestedQuantity = Math.ceil(
        backorderQty +
          alertGroup.fromInventory.minQuantity -
          quantity -
          alertGroup.fromInventory.inComingQuantity
      );

      if (suggestedQuantity > 0) {
        //update suggested quantity and && real time usable stock
        alertGroup.children = [
          ...alertGroup.children.filter((x: StockRequest) => x.id !== alert.id),
          {
            ...alert,
            inventory: {
              ...alert.inventory,
              quantity: quantity,
              forecastOos:
                quantity === 0
                  ? `${moment().unix()}`
                  : alertGroup.fromInventory.forecastOos,
            },
            suggestedQuantity: suggestedQuantity,
            quantity:
              suggestedQuantity === alert.suggestedQuantity
                ? alert.quantity
                : suggestedQuantity,
          },
        ];
      } else {
        alertGroup.children = alertGroup.children.filter(
          (x: StockRequest) => x.id !== alert.id
        );
      }
    });
  }
}

export function countTransferQty(alertGroup: StockRequestGroup) {
  return alertGroup.children
    .filter((x: StockRequest) =>
      [
        SuggestedCommand.st,
        SuggestedCommand.stOnly,
        SuggestedCommand.stOos,
      ].includes(x.command)
    )
    .reduce((prev: number, cur: StockRequest) => prev + cur.quantity, 0);
}

export function countPurchaseQty(alertGroup: StockRequestGroup) {
  return alertGroup.children
    .filter((x: StockRequest) => x.command === SuggestedCommand.po)
    .reduce((prev: number, cur: StockRequest) => prev + cur.quantity, 0);
}

/* Enrich stock request with: 
  1. name: slugify sku+product name => used for search
  2. quantity: if stock request has been confirmed, use its own value, else use suggested quantity
  3. command: the same as above
  4. selectedSupplier: set default as first supplier
  5. transferLeadTime: get matching transfer lead time with its siteId
 */
export function enrichStockRequest(stockRequest: StockRequest): StockRequest {
  const transferLeadTime = stockRequest.transferLeadTimes.find(
    (x: LeadTime) =>
      x.siteId === stockRequest.inventory.siteId &&
      stockRequest.fromInventories[0].siteId === x.fromSiteId
  );
  return {
    ...stockRequest,
    name: slugify(`${stockRequest.stock.sku} ${stockRequest.stock.name}`, {
      locale: 'vi',
      lower: true,
    }),
    quantity:
      stockRequest.state === StockRequestStatus.open
        ? stockRequest.suggestedQuantity
        : stockRequest.quantity,
    suggestedCommand: stockRequest.suggestedCommand,
    command:
      stockRequest.state === StockRequestStatus.open
        ? stockRequest.suggestedCommand
        : stockRequest.command,
    selectedSupplier: stockRequest.supplies[0]?.supplierId,
    transferLeadTime: transferLeadTime,
    backorderQuantity:
      stockRequest.type === StockRequestType.backorder
        ? stockRequest.suggestedQuantity
        : 0,
  };
}

export function createStockRequestForDC(
  stockRequestGroup: StockRequestGroup,
  usableStock: number,
  suggestedQuantity: number
): StockRequest {
  return {
    id: -1, // must be flagged with id -1 in order to identify supply site restock alert created by FE
    stock: stockRequestGroup.stock,
    inventory: {
      ...stockRequestGroup.fromInventory,
      quantity: usableStock,
      forecastOos:
        usableStock === 0
          ? `${moment().unix()}`
          : stockRequestGroup.fromInventory.forecastOos,
    },
    fromInventories: [stockRequestGroup.fromInventory],
    supplies: stockRequestGroup.supplies,
    remainQuantity: stockRequestGroup.fromInventory.quantity,
    inComingQuantity: stockRequestGroup.fromInventory.inComingQuantity,
    averageSale: stockRequestGroup.fromInventory.averageSale,
    isUrgent: true,
    suggestedQuantity: suggestedQuantity,
    quantity: suggestedQuantity,
    suggestedCommand: SuggestedCommand.po,
    command: SuggestedCommand.po,
    transferLeadTimes: [],
    purchaseLeadTimes: [],
    state: StockRequestStatus.open,
    message: '',
    expectedReceivedAt: '',
    referenceId: '',
    referenceMessage: '',
    purchaseReferenceId: '',
    purchaseReferenceMessage: '',
    type: StockRequestType.alert,
    orderCode: '',
  };
}

export function createStockRequestGroup(
  index: number,
  stockRequest: StockRequest
) {
  const distinctSupplier = new Set(
    stockRequest.supplies.map((x: Supplier) => x.supplierId)
  );
  return {
    index: index,
    stock: stockRequest.stock,
    name: slugify(`${stockRequest.stock.sku} ${stockRequest.stock.name}`, {
      locale: 'vi',
      lower: true,
    }),
    fromInventory: stockRequest.fromInventories[0],
    supplies: Array.from(distinctSupplier).map((id: string) =>
      stockRequest.supplies.find(
        (supplier: Supplier) => supplier.supplierId === id
      )
    ),
    selectedSupplier: (stockRequest.supplies[0] || {}).supplierId || '',
    state: stockRequest.state,
    loading: false,
    status: stockRequest.inventory.status,
    children: [enrichStockRequest(stockRequest)],
  } as StockRequestGroup;
}

export function getEDD(record: StockRequest, group: StockRequestGroup) {
  const { expectedReceivedAt, command, state, inventory } = record;
  const { transferLeadTime, purchaseLeadTimes } = record;
  if (state !== StockRequestStatus.open) {
    return expectedReceivedAt;
  } else {
    switch (command) {
      case SuggestedCommand.st:
      case SuggestedCommand.stOos:
      case SuggestedCommand.stOnly:
        //get from transfer lead time
        return transferLeadTime?.leadTime;
      case SuggestedCommand.po:
        //get from purchase lead time
        if (inventory.siteId === group.fromInventory.siteId) {
          return record.purchaseLeadTimes[0]?.leadTime;
        } else {
          const matchPurchaseLeadTime = purchaseLeadTimes.find(
            (x: any) =>
              x.siteId === inventory.siteId &&
              x.fromSiteId === group.fromInventory.siteId &&
              x.supplierId === group.selectedSupplier
          );
          return matchPurchaseLeadTime?.leadTime;
        }
      default:
        return null;
    }
  }
}

export function buildStockRequestGroupList(
  stockRequests: StockRequest[],
  tab: Tab
) {
  let groupedSr: Array<StockRequestGroup> = [];
  stockRequests.forEach((sr: StockRequest) => {
    //check if inventory exist
    if (sr.fromInventories.length > 0) {
      //group by: sku, site id, state
      const found = groupedSr.findIndex(
        (ele: StockRequestGroup) =>
          ele.fromInventory.sku === sr.fromInventories[0].sku &&
          ele.fromInventory.siteId === sr.fromInventories[0].siteId &&
          ele.state === sr.state
      );
      if (found < 0) {
        groupedSr.push(createStockRequestGroup(groupedSr.length, sr));
      } else {
        groupedSr[found].children.push(enrichStockRequest(sr));
      }
    }
  });

  //sort alert by state
  sortByState(groupedSr);

  groupedSr.forEach((item: StockRequestGroup) => {
    switch (tab) {
      case '1':
        validateStockAlert(item);
        break;
      case '2':
        allocateStock(item);
        break;
      default:
    }
    item.stQuantity = countTransferQty(item);
    if (tab === '1') restockForSupplySite(item);
    item.poQuantity = countPurchaseQty(item);
  });
  return groupedSr;
}

export function allocateStock(group: StockRequestGroup) {
  let transferQty = 0;
  group.children.forEach((stockRequest: StockRequest, index: number) => {
    const DCAvailStock = group.fromInventory.quantity - transferQty;
    if (DCAvailStock === 1) {
      if (index === 0) {
        if (stockRequest.inventory.quantity === 0) {
          stockRequest.quantity = 1;
          transferQty = transferQty + 1;
        } else {
          stockRequest.quantity = 0;
        }
      } else {
        stockRequest.quantity = 0;
      }
    } else if (DCAvailStock > 0) {
      stockRequest.quantity = 1;
      transferQty = transferQty + 1;
    } else {
      stockRequest.quantity = 0;
    }
  });
}
