import { message } from 'antd';
import { t } from 'helpers/i18n';
import {
  buildActionStockRequestParams,
  buildStockRequestParams,
} from 'helpers/queryBuilder';
import {
  buildStockRequestGroupList,
  enrichStockRequest,
} from 'helpers/stockRequest';
import { StockRequest } from 'models/StockRequest';
import { StockRequestGroup } from 'models/StockRequestGroup';
import { SuggestedCommand } from 'models/SuggestedCommand';
import React from 'react';
import {
  listStockRequests,
  postActionStockRequests,
} from 'services/apis/stockAlert';
import { Tab } from './state';

export const tabMapping = {
  '1': 'stockAlerts',
  '2': 'transferAlerts',
  '3': 'processing',
  '4': 'purchaseAlerts',
};

export const setTabMapping = {
  '1': 'SET_STOCK_ALERTS',
  '2': 'SET_TRANSFER_ALERTS',
  '3': 'SET_PROCESSING_DATA',
  '4': 'SET_PURCHASE_ALERTS',
};

export function startFetching(dispatch: any, tab: Tab) {
  dispatch({ type: 'START_FETCHING', payload: tab });
}

export function stopFetching(dispatch: any, tab: Tab) {
  dispatch({ type: 'STOP_FETCHING', payload: tab });
}

export function setStockRequestGroup(
  dispatch: any,
  payload: StockRequestGroup[],
  tab: string
) {
  //remove empty StockRequestGroup
  let stockRequestGroup = payload.filter(
    (group: StockRequestGroup) => group.children.length > 0
  );
  // if tab === 2, available stock === 0, remove!
  if (tab === '2')
    stockRequestGroup = stockRequestGroup.filter(
      (group: StockRequestGroup) => group.fromInventory.quantity > 0
    );
  //recalculate index
  stockRequestGroup.forEach((item: StockRequestGroup, index: number) => {
    item.index = index;
  });
  dispatch({
    type: setTabMapping[tab as Tab],
    payload: stockRequestGroup,
  });
}

export function setStockRequest(dispatch: any, payload: StockRequest[]) {
  dispatch({
    type: setTabMapping['4'],
    payload,
  });
}

export function setSingStockRequestGroup(
  dispatch: any,
  payload: StockRequestGroup,
  active: string
) {
  dispatch({
    type: 'SET_SINGLE_STOCK_REQUEST_GROUP',
    payload: {
      tab: active,
      data: payload,
    },
  });
}

export function setSingleStockRequest(dispatch: any, payload: StockRequest) {
  dispatch({
    type: 'SET_SINGLE_PURCHASE_ALERT',
    payload: payload,
  });
}

export function removeSingleStockRequest(
  dispatch: any,
  state: any,
  payload: StockRequest
) {
  const stockRequests = state.purchaseAlerts.filter(
    (x: StockRequest) => x.id !== payload.id
  );

  dispatch({
    type: setTabMapping['4'],
    payload: stockRequests,
  });
}

export const getStockRequest = async (dispatch: any, state: any, tab?: Tab) => {
  const tabsToFetch = tab ? [tab] : window.config;
  const search = state.search;
  tabsToFetch.forEach(async (tabToFetch: any) => {
    startFetching(dispatch, tabToFetch.key);
    const params = buildStockRequestParams(tabToFetch.key, search);
    const response = await listStockRequests(params);
    if (response && response.ok) {
      let groupedSr = [];
      //group stock request by inventories && state
      switch (tabToFetch.key) {
        case '1':
        case '2':
          groupedSr = buildStockRequestGroupList(
            response.stockRequests,
            tabToFetch.key
          );
          setStockRequestGroup(dispatch, groupedSr, tabToFetch.key);
          break;
        case '3':
          groupedSr = response.stockRequests.map((x: StockRequest) =>
            enrichStockRequest(x)
          );
          setProcessingTab(dispatch, groupedSr);
          break;
        case '4':
          groupedSr = response.stockRequests.map((x: StockRequest) =>
            enrichStockRequest(x)
          );
          setStockRequest(dispatch, groupedSr);
          break;
        default:
      }
    } else {
      setStockRequestGroup(dispatch, [], tabToFetch.key);
    }
    stopFetching(dispatch, tabToFetch.key);
  });
};

export function setProcessingTab(dispatch: any, payload: StockRequest[]) {
  dispatch({
    type: setTabMapping['3'],
    payload: payload,
  });
}

export const updateFilter = async (dispatch: any, payload: any) => {
  dispatch({ type: 'UPDATE_FITER', payload });
};

export const updateStockRequest = async (
  stockRequests: StockRequestGroup | StockRequest[]
) => {
  const list = Array.isArray(stockRequests)
    ? stockRequests
    : stockRequests.children.map((x: StockRequest) => ({
        ...x,
        selectedSupplier: stockRequests.selectedSupplier,
      }));

  // create update stock requests params instance
  const params = buildActionStockRequestParams(list);
  const response = await postActionStockRequests(params);
  if (response.ok) {
    const sentIds = list.map((x: StockRequest) => x.id);
    if (response.stockRequests && response.stockRequests.length > 0) {
      const generatedAlert = response.stockRequests.find(
        (x: StockRequest) => !sentIds.includes(x.id)
      );
      if (generatedAlert) generatedAlert.id = -1;
      const successST = response.stockRequests
        .filter((x: StockRequest) => x.command === SuggestedCommand.st)
        .map((x: StockRequest) => x.id);

      const failedST = list.filter(
        (x: StockRequest) =>
          x.command === SuggestedCommand.st && !successST.includes(x.id)
      );
      const successDraftPO = response.stockRequests
        .filter((x: StockRequest) =>
          [SuggestedCommand.poOnly, SuggestedCommand.po].includes(x.command)
        )
        .map((x: StockRequest) => x.id);

      const failedDraftPO = list.filter(
        (x: StockRequest) =>
          x.command === SuggestedCommand.po && !successDraftPO.includes(x.id)
      );
      if (successST.length > 0 || successDraftPO.length > 0)
        message.success(
          <div>
            {successST.length > 0 && (
              <div>
                {successST.length} {t('CreateStSuccess')}
              </div>
            )}
            {successDraftPO.length > 0 && (
              <div>
                {successDraftPO.length} {t('CreatePoSuccess')}
              </div>
            )}
          </div>
        );
      if (failedST.length > 0 || failedDraftPO.length > 0)
        message.error(
          <div>
            {failedST.length > 0 && (
              <div>{t('CreateStFailed', { n: failedST.length })}</div>
            )}
            {failedDraftPO.length > 0 && (
              <div>{t('AddBasketFailed', { n: failedDraftPO.length })}</div>
            )}
          </div>
        );
    } else {
      message.error('Có lỗi xảy ra ');
    }
  }
  return response;
};

export function updateSearch(dispatch: any, item: any) {
  dispatch({ type: 'UPDATE_SEARCH', payload: item });
}

export function setActive(dispatch: any, tab: string) {
  dispatch({ type: 'SET_ACTIVE', payload: tab });
}
