import apiService from './base';

export const fetchCategories: any = () => {
  return apiService({
    url: '/api/v1/categories',
  });
};

export const listSupplySites: any = () => {
  return apiService({
    url: '/api/v1/supply-sites',
  });
};

export const listSellers: any = () => {
  return apiService({
    url: '/api/v1/sellers',
  });
};
