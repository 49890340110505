import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import TekoID from 'teko-oauth2';
import { notification } from 'antd';
import qs from 'qs';

axios.defaults.baseURL = window.REACT_APP_SUPPLY_CHAIN_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.validateStatus = function() {
  return true;
};
axios.defaults.paramsSerializer = function(params) {
  return qs.stringify(params, { arrayFormat: 'repeat' });
};

function apiService(config: AxiosRequestConfig): Promise<AxiosResponse> {
  const { user } = TekoID;

  const instance = axios.create();
  instance.interceptors.request.use(function(config) {
    if (user && user.isLoggedIn()) {
      config.headers.Authorization = `Bearer ${user.getAccessToken()}`;
    }
    if (config.params) {
      Object.keys(config.params).forEach(x => {
        if (
          config.params[x] === null ||
          config.params[x] === '' ||
          config.params[x] === undefined ||
          (Array.isArray(config.params[x]) && config.params[x].length === 0)
        ) {
          delete config.params[x];
        }
      });
    }
    if (config.data) {
      Object.keys(config.data).forEach(x => {
        if (
          config.data[x] === null ||
          config.data[x] === '' ||
          config.data[x] === undefined ||
          (Array.isArray(config.data[x]) && config.data[x].length === 0)
        ) {
          delete config.data[x];
        }
      });
    }
    return config;
  });
  // Add a response interceptor
  instance.interceptors.response.use(
    function(response) {
      const { data } = response;
      if (response.status !== 200) {
        //TODO: show snack bar error
        notification.error({
          message: response.status,
          description: response.data.message || response.statusText,
          duration: 3,
        });
        return response;
      }
      return data;
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
    },
    function(error) {
      if (error.response) {
        //TODO: show snack bar error
        notification.error({
          message: error.response.status,
          description: error.response.data.message || error.response.statusText,
          duration: 3,
        });
      }
      return Promise.reject(error);
    }
  );
  return instance.request(config);
}

export default apiService;
