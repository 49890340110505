const getWindowDimensions = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export const cleanObject = (obj: any) => {
  Object.keys(obj).forEach(x => {
    if (
      obj[x] === null ||
      obj[x] === '' ||
      obj[x] === undefined ||
      (Array.isArray(obj[x]) && obj[x].length === 0)
    ) {
      delete obj[x];
    }
  });
};

export const formatNumber = (number: any, options?: Intl.NumberFormatOptions) =>
  number === null || number === undefined || Number.isNaN(Number(number))
    ? 'N/A'
    : new Intl.NumberFormat('en-EN', options).format(number);

export default {
  getWindowDimensions,
};
