import {
  DownOutlined,
  LogoutOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Avatar, Badge, Dropdown, Layout, Menu } from 'antd';
import { localizationConstants } from 'constants/index';
import { StoreContext } from 'contexts';
import { usePoState } from 'contexts/POContext';
import { localizationHelpers, userHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { IRegionItem } from 'interfaces';
import { PurchaseCommand } from 'models/PurchaseCommand';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

const { Header } = Layout;
const { REGIONS } = localizationConstants;
const { getCurrentLanguage, changeLanguage } = localizationHelpers;
const { logout } = userHelpers;

const AppHeader: React.FC = () => {
  const { currentUser } = useContext(StoreContext);
  const state = usePoState();

  const poDraft = state.data.reduce(
    (quantity: number, current: PurchaseCommand) => {
      return quantity + current.purchaseOrders.length;
    },
    0
  );

  const localizationMenu = (
    <Menu>
      {Object.values(REGIONS).map((el: IRegionItem) => (
        <Menu.Item key={el.key} onClick={() => changeLanguage(el.key)}>
          <Avatar src={el.flag} shape="square" />
          <span style={{ marginLeft: 10 }}>{el.name}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const userMenu = (
    <Menu>
      <Menu.Item data-testid="btn-logout" onClick={logout}>
        <LogoutOutlined />
        <span>{t('Logout')}</span>
      </Menu.Item>
    </Menu>
  );

  const currentRegion = REGIONS[getCurrentLanguage()];

  return (
    <Header className="app-header">
      {/* You can implement notification feature here */}
      <Link to="/stock-requests/pending-orders">
        <Badge count={poDraft} showZero className="app-icon">
          <ShoppingCartOutlined style={{ fontSize: 25 }} />
        </Badge>
      </Link>

      <Dropdown overlay={localizationMenu} trigger={['click']}>
        <span className="app-user">
          <Avatar src={currentRegion && currentRegion.flag} shape="square" />
          <span className="name">{currentRegion && currentRegion.name}</span>
        </span>
      </Dropdown>

      <Dropdown overlay={userMenu} trigger={['click']}>
        <span className="app-user">
          <Avatar src={currentUser.picture} />
          <span className="name">{currentUser.name}</span>
          <DownOutlined />
        </span>
      </Dropdown>
    </Header>
  );
};

export default AppHeader;
