import React, {
  FC,
  createContext,
  useReducer,
  useContext,
  useEffect,
} from 'react';
import {
  startFetching as startAppFetching,
  stopFetching as stopAppFetching,
  useApp,
  getCategories,
} from '../AppContext';
import initialState from './state';
import SAReducer from './reducer';

//contexts
const SAStateContext = createContext<any | undefined>(undefined);
const SADispatchContext = createContext<any | undefined>(undefined);

//context provider
const SAProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(SAReducer, initialState);
  const [appState, appDispatch] = useApp();

  useEffect(() => {
    startAppFetching(appDispatch);

    Promise.all([
      appState.categories.length === 0 ? getCategories(appDispatch) : null,
    ])
      .then(() => {
        stopAppFetching(appDispatch);
      })
      .catch(() => {
        stopAppFetching(appDispatch);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <SAStateContext.Provider value={state}>
      <SADispatchContext.Provider value={dispatch}>
        {children}
      </SADispatchContext.Provider>
    </SAStateContext.Provider>
  );
};

function useSAState() {
  const context = useContext(SAStateContext);
  return context;
}

function useSADispatch() {
  const dispatch = useContext(SADispatchContext);
  return dispatch;
}

function useStockAlert() {
  return [useSAState(), useSADispatch()];
}

export { SAProvider, useSAState, useSADispatch, useStockAlert };
