import { StockRequest } from 'models/StockRequest';
import { StockRequestGroup } from 'models/StockRequestGroup';

type SAFilterType = {
  searchKey: string;
  searchBy: string;
};

type SASearchType = {
  fromSiteIds: string[];
};

export type Tab = '1' | '2' | '3' | '4';

export type StockAlertState = {
  active: Tab;
  filter: SAFilterType;
  search: SASearchType;
  stockAlerts: StockRequestGroup[];
  transferAlerts: StockRequestGroup[];
  purchaseAlerts: StockRequest[];
  processing: StockRequestGroup[];
  fetching: Tab[];
};

const initialState: StockAlertState = {
  active: '1',
  filter: {
    searchKey: '',
    searchBy: 'sku',
  },
  search: {
    fromSiteIds: [],
  },
  stockAlerts: [],
  transferAlerts: [],
  purchaseAlerts: [],
  processing: [],
  fetching: [],
};

export default initialState;
