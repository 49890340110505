import React from 'react';
import { Layout } from 'antd';
import { userHooks } from 'hooks';
import routes from 'routes';
import { IRoute } from 'interfaces';
// import AppSider from './AppSider';
import AppHeader from './AppHeader';
import AppContent from './AppContent';
import './AppLayout.scss';
import { SAProvider } from 'contexts/SAContext';
import { AppProvider } from 'contexts/AppContext';
import { PoProvider } from 'contexts/POContext';

const MemoizedAppHeader = React.memo(AppHeader);
const AppLayout: React.FC = () => {
  const { filteredRoutes } = userHooks.useAuthorizationData(routes as IRoute[]);

  return (
    <Layout className="app-layout">
      {/* <AppSider filteredNavigation={filteredNavigation} /> */}
      <Layout>
        <AppProvider>
          <SAProvider>
            <PoProvider>
              {!window.isIFrame && <MemoizedAppHeader />}
              <AppContent filteredRoutes={filteredRoutes} />
            </PoProvider>
          </SAProvider>
        </AppProvider>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
