import { Spin } from 'antd';
import PrivateRoute from 'components/shared/PrivateRoute';
import { commonConstants } from 'constants/index';
import AppLayout from 'containers/AppLayout';
import Page403 from 'containers/shared/Page403';
import Page404 from 'containers/shared/Page404';
import Page500 from 'containers/shared/Page500';
import { browserHistory } from 'helpers';
import { t } from 'helpers/i18n';
import userHelpers from 'helpers/user';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import ReactTracker from 'react-tracker-teko';
import { listSellers } from 'services/apis/common';
import { getLastSeller, postSelectSeller } from 'services/apis/user';
import TekoID from 'teko-oauth2';
import './App.less';
import './App.scss';

moment.defaultFormat = 'DD/MM/YYYY';
const { IAM_SCOPES } = commonConstants;

const reactTracker = new ReactTracker({
  // Configure your tracker server and site by providing
  host:
    (window.REACT_APP_TRACKER_APP_ID && window.REACT_APP_TRACKER_HOST) || '',
  urlServeJsFile:
    (window.REACT_APP_TRACKER_APP_ID && window.REACT_APP_TRACKER_JS_FILE) || '',
  appId: window.REACT_APP_TRACKER_APP_ID || '',
});

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [isAllowed, setAllowed] = useState(true);
  const parentClientId = _.get(
    window.iFrameData,
    ['clientId'],
    window.REACT_APP_IAM_CLIENT_ID
  );
  const parentClientOauth = _.get(
    window.iFrameData,
    ['oauthDomain'],
    window.REACT_APP_IAM_OAUTH_DOMAIN
  );
  useEffect(() => {
    //use parent app client info if exists
    TekoID.init({
      clientId: parentClientId,
      scopes: IAM_SCOPES,
      oauthDomain: parentClientOauth,
    }).then(() => {
      // The initial phase is finish.
      // Now you can do your logic.
      listSellers().then((response: any) => {
        const { sellers } = response;
        if (window.isIFrame) {
          const isSupported = sellers.find(
            (x: any) => x.id === window.currentSeller
          );
          if (!isSupported) {
            setAllowed(false);
            setLoading(false);
          } else {
            postSelectSeller(window.currentSeller).then(() => {
              setLoading(false);
            });
          }
        } else {
          getLastSeller().then((response: any) => {
            window.currentSeller = response.staff.seller.id;
            window.config = userHelpers.getSellerConfig(
              Number(window.currentSeller)
            );
            setLoading(false);
          });
        }
      });
    });
  }, [parentClientId, parentClientOauth]);

  return loading ? (
    <Spin className="app-spin" />
  ) : (
    <Router history={reactTracker.connectToHistory(browserHistory)}>
      <Switch>
        <Route exact path="/403" name="403" component={Page403} />
        <Route exact path="/404" name="404" component={Page404} />
        <Route exact path="/500" name="500" component={Page500} />
        <PrivateRoute path="/" name={t('Home')} component={AppLayout} />
      </Switch>
      {!isAllowed && <Redirect to="/403" />}
    </Router>
  );
};

export default App;
