import apiService from './base';
import { StockRequestStatus } from 'models/StockRequestStatus';
import { SuggestedCommand } from 'models/SuggestedCommand';

type StockRequestParams = {
  sku?: string;
  siteId?: string;
  fromSiteIds?: string[];
  states: StockRequestStatus[];
  purchaseOrderId?: number;
};

export const listStockRequests: any = (params: StockRequestParams) => {
  return apiService({
    url: '/api/v1/stock-requests',
    params,
  });
};

type ActionStockRequestParams = {
  id: number;
  fromSiteId: string;
  supplierId: string;
  quantity: number;
  command?: SuggestedCommand;
  state?: StockRequestStatus;
  remainQuantity?: number;
  suggestedQuantity?: number;
  message?: string;
};

export const postActionStockRequests: any = (
  params: ActionStockRequestParams[]
) =>
  apiService({
    url: '/api/v1/action-stock-requests',
    method: 'post',
    data: params,
  });

type GetEDDParams = {
  orderDate: string;
  receiveSiteId: string;
  supplierId: string;
  supplySiteId: string;
};

export const getExpectedDeliveryDate: any = (params: GetEDDParams) =>
  apiService({
    url: '/api/v1/expected-delivery-date',
    method: 'get',
    params: params,
  });

type UpdateStockRequestParams = {
  id: number;
  fromSiteId: string;
  quantity: number;
};

export const postUpdateStockRequest: any = (data: UpdateStockRequestParams) =>
  apiService({
    url: '/api/v1/stock-request',
    method: 'post',
    data: {
      stockRequest: data,
    },
  });
