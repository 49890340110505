import localizationConstants from 'constants/localization';
import localStorageConstants from 'constants/localStorage';

const { LOCALIZATION } = localStorageConstants;
const { REGIONS } = localizationConstants;

const getCurrentLanguage = () => {
  const language =
    window.localization || localStorage.getItem(LOCALIZATION) || REGIONS.vi.key;
  return language;
};

const changeLanguage = (language: string) => {
  if (language === getCurrentLanguage()) return;
  localStorage.setItem(LOCALIZATION, language);
  window.location.reload();
};

export default {
  getCurrentLanguage,
  changeLanguage,
};
