import { StockRequest } from './StockRequest';
import { StockRequestStatus } from './StockRequestStatus';
import { Supplier } from './Supplier';

export type PurchaseCommand = {
  id: number;
  index: number;
  purchaseOrders: PurchaseOrder[];
  fromSiteId: string;
  fromSiteName: string;
  supplierId: string;
  supplierName: string;
  confirmedBy: string;
  state: StockRequestStatus;
  referenceId: string;
  expectedReceivedAt: string;
  createdAt: string;
  lateStockRequests?: number;
  mov: number;
  // -------------- FE generated fields ---------------------
  suppliers?: Supplier[];
};

export type PurchaseOrder = {
  id: number;
  sku: string;
  name: string;
  categoryId: string;
  categoryName: string;
  requestedQuantity: number;
  quantity: number;
  price: number[];
  selectedPrice: number;
  vat: Vat[];
  selectedVat: number;
  uom: Uom[];
  selectedUom: string;
  stockRequests: StockRequest[] | null;
  displayName: string;
  selected: boolean;
  selectedSupplier: string;
};

export type Vat = {
  amount: number;
  code: string;
  label: string;
};

type Uom = {
  code: string;
  label: string;
};

export enum PurchaseCommandStatus {
  unspecified = 'PURCHASE_COMMAND_STATE_UNSPECIFIED',
  requested = 'PURCHASE_COMMAND_STATE_REQUESTED',
  failed = 'PURCHASE_COMMAND_STATE_FAILED',
  completed = 'PURCHASE_COMMAND_STATE_COMPLETED',
  open = 'PURCHASE_COMMAND_STATE_OPEN',
}
