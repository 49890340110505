import { Inventory } from './Inventory';
import { LeadTime } from './LeadTime';
import { Stock } from './Stock';
import { StockRequestStatus } from './StockRequestStatus';
import { SuggestedCommand } from './SuggestedCommand';
import { Supplier } from './Supplier';

export enum StockRequestType {
  unspecified = 'STOCK_REQUEST_TYPE_UNSPECIFIED',
  alert = 'STOCK_REQUEST_TYPE_ALERT',
  backorder = 'STOCK_REQUEST_TYPE_BACKORDER',
}

export type StockRequest = {
  //-----------BE fields-------------------
  id: number;
  stock: Stock;
  inventory: Inventory;
  fromInventories: Inventory[];
  supplies: Supplier[];
  remainQuantity: number;
  inComingQuantity: number;
  averageSale: number;
  isUrgent: boolean;
  suggestedQuantity: number;
  quantity: number;
  suggestedCommand: SuggestedCommand;
  command: SuggestedCommand;
  transferLeadTimes: LeadTime[];
  purchaseLeadTimes: LeadTime[];
  state: StockRequestStatus;
  message: string; // contain reject reason
  expectedReceivedAt: string; // unix timestamp string
  referenceId: string; // ST code
  referenceMessage: string; // ST status
  purchaseReferenceId: string;
  purchaseReferenceMessage: string; // used for display PO status
  type: StockRequestType;
  orderCode: string;
  //-------FE custom field-----------------
  ableToStockTransfer?: number;
  transferLeadTime?: LeadTime;
  selectedSupplier?: string;
  name?: string; // combined sku & name
  backorderQuantity?: number;
};
