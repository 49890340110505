import { ShoppingCartOutlined } from '@ant-design/icons';
import { Badge, Breadcrumb, Col, Row } from 'antd';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { usePoState } from 'contexts/POContext';
import { PurchaseCommand } from 'models/PurchaseCommand';
import React from 'react';
import { Link } from 'react-router-dom';
interface AppBreadcrumbProps {
  crumbs: Route[];
}

const AppBreadcrumb: React.FC<AppBreadcrumbProps> = props => {
  const { crumbs } = props;
  const state = usePoState();

  const poDraft = state.data.reduce(
    (quantity: number, current: PurchaseCommand) => {
      return quantity + current.purchaseOrders.length;
    },
    0
  );
  const itemRender = (route: Route, params: object, routes: Route[]) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={route.path}>{route.breadcrumbName}</Link>
    );
  };

  return (
    <Row>
      <Col span={20}>
        <Breadcrumb
          className="app-breadcrumb"
          itemRender={itemRender}
          routes={crumbs}
        />
      </Col>
      {window.isIFrame && (
        <Col span={4} className="text-right p-base">
          <Link to="/stock-requests/pending-orders">
            <Badge count={poDraft} showZero className="app-icon">
              <ShoppingCartOutlined style={{ fontSize: 25 }} />
            </Badge>
          </Link>
        </Col>
      )}
    </Row>
  );
};

export default AppBreadcrumb;
