import { ConfigProvider } from 'antd';
import { localizationConstants } from 'constants/index';
import { localizationHelpers, userHelpers } from 'helpers';
import i18n from 'i18n';
import { IFrameData } from 'interfaces/iframe';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import 'styles/EnvironmentRibbon.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

const { REGIONS } = localizationConstants;
const { getCurrentLanguage } = localizationHelpers;

let iFrameData: IFrameData = {} as IFrameData;
try {
  if (window.name) iFrameData = JSON.parse(window.name);
} catch {}
window.iFrameData = iFrameData;
window.localization = iFrameData.localization;
window.currentSeller = iFrameData.currentSeller || '1';
window.isIFrame = Boolean(
  iFrameData && iFrameData.localization && iFrameData.currentSeller
);
window.config = userHelpers.getSellerConfig(Number(window.currentSeller));

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ConfigProvider locale={REGIONS[getCurrentLanguage()].antdLocale}>
      <div className={`corner-ribbon left ${window.ENV_MODE}`}>
        {window.ENV_MODE}
      </div>
      <App />
    </ConfigProvider>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
