import { StockRequest } from 'models/StockRequest';
import { StockRequestStatus } from 'models/StockRequestStatus';
import { SuggestedCommand } from 'models/SuggestedCommand';
import { cleanObject } from './common';

// used for get /stock-requests
export function buildStockRequestParams(tab: string, search: any) {
  let defaultParams = {
    fromSiteIds: search.fromSiteIds,
  };
  switch (tab) {
    case '1':
      return {
        ...defaultParams,
        states: [StockRequestStatus.open],
        suggestedCommands: [
          SuggestedCommand.st,
          SuggestedCommand.po,
          SuggestedCommand.stOnly,
        ],
      };
    case '2':
      return {
        ...defaultParams,
        states: [StockRequestStatus.open],
        suggestedCommands: [SuggestedCommand.stOos],
      };
    case '3':
      return {
        ...defaultParams,
        states: [StockRequestStatus.requested, StockRequestStatus.pending],
      };
    case '4':
      return {
        ...defaultParams,
        states: [StockRequestStatus.open],
        suggestedCommands: [SuggestedCommand.poOnly],
      };
    default:
      return {
        states: [
          StockRequestStatus.open,
          StockRequestStatus.requested,
          StockRequestStatus.pending,
        ],
        fromSiteIds: search.fromSiteIds,
      };
  }
}

// used for post /action-stock-requests
export function buildActionStockRequestParams(list: StockRequest[]) {
  let minifiedStockRequests = [];

  minifiedStockRequests = list.map((sr: StockRequest) => {
    let params: any = {
      fromSiteId: sr.fromInventories[0].siteId,
      supplierId: sr.selectedSupplier,
      quantity: sr.quantity,
      id: sr.id > 0 ? sr.id : null,
      sku: sr.id < 0 ? sr.stock.sku : null,
    };

    switch (sr.command) {
      case SuggestedCommand.reject:
        params.state = StockRequestStatus.rejected;
        params.message = sr.message || 'reject';
        params.remainQuantity = sr.inventory.quantity;
        break;
      case SuggestedCommand.stOos:
        if (sr.quantity === 0) {
          params.state = StockRequestStatus.rejected;
          params.message = 'Not enough stock at DC';
          params.remainQuantity = sr.inventory.quantity;
        } else {
          params.command = SuggestedCommand.st;
        }
        break;
      default:
        params.command = sr.command;
        params.message = 'confirm';
        //if stock request is for DC => update remainQty
        if (sr.fromInventories[0].siteId === sr.inventory.siteId) {
          params.remainQuantity = sr.inventory.quantity;
          params.suggestedQuantity = sr.suggestedQuantity;
        }
    }
    cleanObject(params);
    return params;
  });

  return { stockRequests: minifiedStockRequests };
}
