import React from 'react';
import { Result } from 'antd';
import { t } from 'helpers/i18n';

const Page403: React.FC = () => {
  return (
    <Result
      className="app-result-page"
      status="403"
      title="403"
      subTitle={t('PermissionDenied')}
    />
  );
};

export default Page403;
